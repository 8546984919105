import type { CookieOptions } from '#app'
import type {
  User,
  Workspace,
} from '~~/types/auth'

import dayjs from 'dayjs'

export default () => {
  // These should match options in app-v1 utils/cookie.js
  const options = {
    domain: useRuntimeConfig().public.cookieDomain,
    secure: useRuntimeConfig().public.stage !== 'development' || window.location.protocol === 'https:',
    sameSite: 'strict',
    expires: dayjs().add(7, 'days').toDate(),
    path: '/',
  } satisfies CookieOptions

  const activeWorkspaceId = useCookie<string | null>('active_account', options)
  const apiToken = useCookie('api_token', options)

  const user = useState<User | undefined>('user')
  const workspace = useState<Workspace | undefined>('workspace')
  const country = computed(() => workspace.value?.attributes?.country)

  const clear = () => {
    activeWorkspaceId.value = null
    apiToken.value = undefined
    user.value = undefined

    workspace.value = undefined
  }

  const staleWhileRevalidate = async <T>(
    key: string,
    fetcher: () => Promise<T>,
  ): Promise<Ref<T>> => {
    const result = ref<T>()

    // We store value in { value: T } only beucase the app-v1 does that
    const cached = useSessionStorage<{ value: T | null }>(
      `${activeWorkspaceId.value!}-${key}`,
      { value: null },
      { listenToStorageChanges: false },
    )
    if (cached.value.value) {
      result.value = cached.value.value
      fetcher().then((r) => {
        cached.value = { value: r }
        result.value = r
      })
    }
    else {
      const r = await fetcher()
      cached.value = { value: r }
      result.value = r
    }

    return result as Ref<T>
  }

  const fetchActiveWorkspaceData = async (options: { refresh: boolean }) => {
    const fetchAndCache = async <T>(ref: Ref<T>, key: string, fetcher: () => Promise<T>) => {
      if (!ref.value || options.refresh)
        syncRefs(await staleWhileRevalidate(key, fetcher), ref)
    }

    await Promise.all([
      fetchAndCache(workspace, 'company', () => useApi().fetchActiveWorkspace().then(r => r.data)),
    ])
  }

  const setWorkspace = async (id: string) => {
    activeWorkspaceId.value = id
    // wait for cookie availability
    await nextTick()
    await fetchActiveWorkspaceData({ refresh: true })
  }

  const isCurrentUserAdmin = computed(() => {
    if (!workspace.value)
      return false

    return workspace.value.relationships?.role?.data.attributes.type === 'admin'
  })

  const isAuthenticated = computed(() => !!apiToken.value)

  const arePremadeTemplatesVisible = computed(() => workspace.value?.attributes.showPremadeTemplates ?? true)

  return {
    user,
    apiToken,
    isAuthenticated,
    activeWorkspaceId,
    workspace,
    fetchActiveWorkspaceData,
    setWorkspace,
    country,
    clear,
    isCurrentUserAdmin,
    arePremadeTemplatesVisible,
  }
}
