import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45app_45version_45global from "/vercel/path0/apps/app/middleware/0-app-version.global.ts";
import _1_45auth_45global from "/vercel/path0/apps/app/middleware/1-auth.global.ts";
import _2_45archive_45version_45global from "/vercel/path0/apps/app/middleware/2-archive-version.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_m2ndwmdnyiy7yogtn2eb6a2zne/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45app_45version_45global,
  _1_45auth_45global,
  _2_45archive_45version_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}