export const appHead = {"link":[{"rel":"preload","href":"https://widget.intercom.io/widget/n5oxoy80","as":"script"},{"rel":"preconnect","href":"https://widget.intercom.io"},{"rel":"preconnect","href":"https://js.intercomcdn.com"},{"rel":"preconnect","href":"https://api-iam.intercom.io"},{"rel":"alternate icon","sizes":"16x16","href":"/favicon.ico"},{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"},{"rel":"apple-touch-icon","href":"/favicon-apple.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"style":[],"script":[],"noscript":[]}

export const appBuildAssetsDir = "/_nuxt3/"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false